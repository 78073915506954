import { Avatar, Box, Button, Divider, IconButton, Link, ListItem, ListItemAvatar, ListItemButton, ListItemText, Menu, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useTheme } from '@mui/material/styles';
import config from "../../config.js";
import { Email, LinkedIn, Place, Star, StarOutline } from "@mui/icons-material";

import CircularProgressWithLabel from "./CircularProgressWithLabel.js";
import { AccIcon } from "./Icon.js";
import { grey } from "@mui/material/colors";
import ServerProxy from "./serverProxy.js";
import { useNavigate } from "react-router-dom";


export default function DrawerListItem(props) {

    const theme = useTheme();
    const navigate = useNavigate();

    const { item, onClickAction, favorites, profile } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const [isFavorite, setIsFavorite] = useState(false);
    const [bestMatch, setBestMatch] = useState(false);


    useEffect(() => {
        setBestMatch(parseFloat(item.match) >= config.bestMatchThreshold);
    }, [item.match]);


    useEffect(() => {
        if (favorites) {
            setIsFavorite(favorites.map(favorite => favorite.id).includes(item.id));
        }

    }, [favorites, item.id]);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClick = async () => {
        const result = await ServerProxy.saveClick(profile.id, item.id)
        handleClose()
    }

    const handleClose = () => {
        setAnchorEl(null);
    }


    const ListItemStyle = {
        p: 1,
        mb: 1,
        width: '100%',
        border: 2,

        borderColor: bestMatch ? theme.palette.primary.main : 'transparent',

        borderImage: bestMatch ? 'inherit' : 'linear-gradient(to right, rgba(192, 8, 127, 0.7) 0%, rgba(192, 8, 127, 0.7) 22%,rgba(236, 227, 20, 0.7) 100%)',
        borderImageSlice: 1,
        //borderImageOutset: 2,

        borderRadius: '5px',
        backgroundColor: bestMatch ? theme.palette.primary.dark : 'background.paper',
        boxShadow: 2

    }


    const manageFavorites = async (item) => {

        if (isFavorite) {
            props.setConfirmMethod({
                item,
                method: props.removeFavorite,
                "callback": () => setIsFavorite(false)
            })


        } else {

            await props.addFavorite(item);
            props.setOpenSnackbar(item.user.firstname + ' ' + item.user.lastname);
            setIsFavorite(true);
        }
    }

    const { firstname, lastname, avatarUrl, email, linkedInUrl } = item.user;

    const onClickActionEvent = async (item) => {

        const profile = await ServerProxy.getDefaultProfile(item.user.id);

        if (profile) {

            return navigate('/user/' + item.user.id + '/profile/' + profile.id)
        }
    }

    return (

        <ListItem sx={{ ...ListItemStyle }}>

            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <ListItemAvatar>
                        <Avatar
                            sx={{ cursor: 'pointer', width: 48, height: 48, mr: .5, border: 2, borderColor: bestMatch ? grey[100] : 'primary.main' }}
                            alt={firstname + ' ' + lastname}
                            onClick={() => onClickAction(profile, item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                            src={item.user.avatarUrl ? config.server.backetUrl + item.user.avatarUrl : null}>{firstname.toUpperCase().charAt(0)}</Avatar>
                    </ListItemAvatar>

                    <ListItemText
                        sx={{ flexGrow: 1 }}

                        primary={<Typography
                            onClick={() => onClickAction(profile, item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                            sx={{ cursor: 'pointer', fontSize: '.9rem', fontWeight: 'bold', color: bestMatch ? grey[100] : 'inherit' }}
                            variant="h6">{firstname + ' ' + lastname}</Typography>}

                        secondary={<Typography
                            onClick={() => onClickAction(profile, item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                            sx={{ cursor: 'pointer', fontSize: '.6rem', whiteSpace: 'nowrap', color: bestMatch ? grey[100] : 'inherit' }}
                            variant="button"
                            display="block" gutterBottom color="text.primary">{item.profileMacroType.name}</Typography>}
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        <Tooltip title="Contacts">
                            <IconButton onClick={handleClick} >
                                <Email sx={{ color: bestMatch ? grey[100] : theme.palette.primary.main }} />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            anchorEl={anchorEl}

                            open={open}
                            onClose={handleClose}
                            onClick={handleMenuClick}

                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >

                            {email &&

                                <ListItemButton component={Link} target="_blank" href={`mailto:${email}`} >
                                    <Email color="primary" sx={{ mr: 1, fontSize: 20 }} />
                                    <ListItemText ><Typography variant="body2">{email}</Typography></ListItemText>
                                </ListItemButton>
                            }

                            {linkedInUrl &&
                                <ListItemButton component={Link} target="_blank" href={linkedInUrl} >
                                    <LinkedIn color="primary" sx={{ mr: 1, fontSize: 20 }} />
                                    <ListItemText ><Typography variant="body2">LinkedIn</Typography></ListItemText>
                                </ListItemButton>
                            }

                        </Menu>
                    </Box>
                </Box>

                <Divider sx={{ width: '100%', my: 1, backgroundColor: bestMatch ? grey[100] : 'inherit' }} />

                <Typography sx={{ mb: 0, color: bestMatch ? grey[100] : 'inherit' }} variant="body2">
                    {item.shortDescription ? (item.shortDescription?.substring(0, 255) + (item.shortDescription?.length > 255 ? "..." : '')) : 'shortDescription'}
                </Typography>

                <Divider sx={{ width: '100%', my: 1, backgroundColor: bestMatch ? grey[100] : 'inherit' }} />

                <Stack spacing={0}>
                    {item.positions.map((position, index) => (

                        <Box sx={{ width: '100%' }}>
                            <Button sx={{ textTransform: 'none' }} onClick={() => props.flyTo(position)} startIcon={<Place sx={{ color: bestMatch ? grey[100] : theme.palette.primary.main }} />} >
                                <Typography sx={{ fontSize: '.8rem', color: bestMatch ? grey[100] : grey[900] }} variant="body2">{position.place}</Typography>
                            </Button>
                        </Box>
                    ))}
                </Stack>

                <Divider sx={{ width: '100%', my: 1, backgroundColor: bestMatch ? grey[100] : 'inherit' }} />

                <Divider sx={{ width: '100%', my: 1, "&::before, &::after": { border: 1.5, borderColor: bestMatch ? grey[100] : grey[300], } }} >
                    <Typography sx={{
                        textAlign: 'center', mx: 0, mb: 0, textTransform: 'uppercase', fontWeight: 'bold',
                        fontSize: 22,
                        width: '20%', color: bestMatch ? grey[100] : 'inherit'
                    }} variant="body" >
                        {bestMatch ? 'IT’s A MATCH!' : 'Match score!'}
                    </Typography>
                </Divider>


                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <CircularProgressWithLabel value={item.match} bestMatch={bestMatch} />
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-end" }} >

                        <IconButton onClick={e => manageFavorites(item)} title={firstname + ' ' + lastname} >
                            {isFavorite ? <Star sx={{ color: bestMatch ? grey[100] : theme.palette.primary.main }} /> : <StarOutline sx={{ color: bestMatch ? grey[100] : theme.palette.primary.main }} />}
                        </IconButton>

                        <IconButton onClick={e => onClickAction(profile, item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname} >
                            <AccIcon fill={bestMatch ? grey[100] : theme.palette.primary.main} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </ListItem>
    )
}