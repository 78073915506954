import { ArrowRightAlt, Email, LinkedIn, Place } from "@mui/icons-material";
import { Avatar, Box, Divider, IconButton, Link, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import BorderLinearProgress from "./BorderLinearProgress";
import config from "../../config";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import ServerProxy from "./serverProxy";
import { useRef } from "react";


export default function TooltipContentProfile(props) {

    const { item, onClick, profile, navigateToProfile } = props;

    const handleClick = () => {
        navigateToProfile(profile, item.firstname ? item : item.profile);
        if (onClick) {
            onClick();
        }
    }

    const handleEmail = async () => {
        await ServerProxy.saveClick(profile.id, item.id);
    }


    return (
        <>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box id="top-container">
                    <Box>
                        <Box onClick={handleClick} sx={{ cursor: 'pointer', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                            <ListItemAvatar sx={{ mr: 2 }}>
                                <Avatar sx={{ width: 56, height: 56 }} alt={item.user.firstname + ' ' + item.user.lastname}
                                    src={item.user.avatarUrl ? config.server.backetUrl + item.user.avatarUrl : null}>{item.user.firstname.charAt(0)}</Avatar>
                            </ListItemAvatar>

                            <ListItemText
                                sx={{ flexGrow: 1 }}
                                primary={<Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }} variant="h6">{item.user.firstname + ' ' + item.user.lastname}</Typography>}
                                secondary={<Typography sx={{ fontSize: '.8rem' }} variant="button" display="block" gutterBottom color="text.primary">{item.profileMacroType.name}</Typography>}
                            />
                        </Box>


                        <Divider sx={{ mb: 1 }} />
                    </Box>

                    <Box sx={{ mb: 2, px: 1, height: '80px', overflow: 'auto', border: '1px solid rgba(0, 0, 0, 0.1)'}}>
                        <Typography sx={{ color: grey[900], fontSize: "13px" }} variant="caption">{item.shortDescription ?? ''}</Typography>
                    </Box>
                </Box>

                <Box id="bottom-container">
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Place color="primary" sx={{ mr: 1 }} /> <Typography variant="body2" >{item.positions[0].place}</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '1rem', textAlign: 'center', fontWeight: 'bold' }} variant="button" >match score</Typography>
                    </Box>

                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                            <Typography sx={{ position: 'absolute', zIndex: '9999', fontWeight: 'bold' }} variant="body2" color="white">{item.match}%</Typography>
                            <BorderLinearProgress sx={{ width: '100%' }} variant="determinate" value={item.match} />
                        </Box>
                    </Box>


                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        {item.user.email && <IconButton component={Link} target="_blank" onClick={handleEmail} href={`mailto:${item.user.email}`} size="small"><Tooltip title={item.user.email}><Email fontSize="inherit" color="primary" /></Tooltip></IconButton>}
                        {item.user.linkedInUrl && <IconButton component={Link} target="_blank" href={item.user.linkedInUrl} size="small"><LinkedIn fontSize="inherit" color="primary" /></IconButton>}

                        <IconButton size="small" onClick={handleClick}><ArrowRightAlt fontSize="inherit" color="primary" /></IconButton>
                    </Box>
                </Box>
            </Box>
        </>
    )
}