import { ChevronRight, Event, Star } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Drawer, IconButton, Typography } from "@mui/material";
import { useState } from "react";

import ConfirmModal from "./ConfirmModal";
import ListFavoritesContainer from "./ListFavoritesContainer";
import { grey } from "@mui/material/colors";
import ListEventItem from "./ListEventItem";

// const AccordionSummaryStyle = {
//     bgcolor: grey[900],
//     color: 'white',

//     '&:hover': {
//         bgcolor: grey[900],
//     },

//     '&.Mui-expanded': {
//         minHeight: 56,
//     },

//     '&.Mui-expanded + .MuiAccordionDetails-root': {
//         bgcolor: grey[100],    
//     },
// };


const DrawerHeader = styled(Box)(({ theme }) => ({

    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    backgroundColor: theme.palette.primary.dark,
    border: 'none',

    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));




export default function RightDrawer(props) {

    const { open, setRightOpen, drawerWidth, favorites, events, removeFavorite, helpPanel } = props;

    const [confirmMethod, setConfirmMethod] = useState({
        item: null,
        method: removeFavorite
    });


    return (

        <Drawer
            sx={{
                border: 'none',
                width: drawerWidth,
                flexShrink: 0,

                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    border: 'none',
                    bgcolor: grey[100],
                },

                zIndex: helpPanel === 8 ? 8888 : 'initial',
                pointerEvents: helpPanel === 8 ? 'none' : 'initial',

            }}

            variant="persistent"
            anchor="right"
            open={open}>

            <ConfirmModal confirmMethod={confirmMethod} setConfirmMethod={setConfirmMethod} />

            <DrawerHeader >

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <Typography variant="body" sx={{ flexGrow: 1, color: "#fff", pl: 1 }}>
                        Matchmaking tools
                    </Typography>

                    <IconButton sx={{ color: "#fff" }} onClick={() => setRightOpen(false)}>
                        <ChevronRight />
                    </IconButton>
                </Box>
            </DrawerHeader>


            <Box sx={{ bgcolor: 'transparent', width: '100%', height: '100%', maxWidth: `${drawerWidth}px`, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>

                    {/* <Accordion defaultExpanded slots={{ transition: Fade }} sx={{boxShadow: 'none', flex: 1}} >
                        
                        <AccordionSummary
                            expandIcon={null}
                            sx={ AccordionSummaryStyle }
                        >
                            <Star sx={{mr: 1}} /><Typography>Favorites ({favorites ? favorites.length : 0})</Typography>
                        </AccordionSummary>

                        <AccordionDetails sx={{height : 'calc(50vh - 106px)',  border: 'none', overflowY: 'auto', bgcolor: '#f4f4f4'}}>
                            
                            { favorites ? (
                                
                                <>
                                    { favorites.length === 0 ?  (
                                        <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', height: '100%', p : 5}}>
                                            <Typography variant="body1" color="text.secondary">No favorites yet</Typography>
                                        </Box>  
                                    ) : 
                                        <>
                                            {favorites.map((item, index) => (
                                                <ListFavoritesContainer item={item} key={index} deleteItem={ () => setConfirmMethod({ item, method: removeFavorite} ) } />    
                                            ))}
                                        </>
                                    }
                                </>

                            ) : (
                                <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', height: '100%', p : 5}}>
                                    <CircularProgress />
                                </Box>
                            )}

                        </AccordionDetails>

                        
                    </Accordion> */}

                    <Box sx={{ width: '100%', display: 'flex', bgcolor: grey[900], p: 2, color: grey[100] }} >
                        <Star sx={{ mr: 1 }} /><Typography>Favorites ({favorites ? favorites.length : 0})</Typography>
                    </Box>

                    <Box sx={{ width: '100%', height: 'calc(50vh - 90px)', border: 'none', overflowY: 'auto', bgcolor: grey[100] }}>
                        {favorites ? (

                            <>
                                {favorites.length === 0 ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: 5 }}>
                                        <Typography variant="body1" color="text.secondary">No favorites yet</Typography>
                                    </Box>
                                ) :
                                    <Box sx={{ p: 1 }}>
                                        {favorites.map((item, index) => (
                                            <ListFavoritesContainer item={item} key={index} deleteItem={() => setConfirmMethod({ item, method: removeFavorite })} />
                                        ))}
                                    </Box>
                                }
                            </>

                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: 5 }}>
                                <CircularProgress />
                            </Box>
                        )}
                    </Box>
                </Box>


                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>

                    <Box sx={{ width: '100%', display: 'flex', bgcolor: grey[900], p: 2, color: grey[100] }} >
                        <Event sx={{ mr: 1 }} /><Typography>Events ({events ? events.length : 0})</Typography>
                    </Box>

                    <Box sx={{ width: '100%', height: 'calc(50vh - 90px)', border: 'none', overflowY: 'auto', bgcolor: grey[100] }}>
                        {events ? (
                            <>
                                {events.length === 0 ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: 5 }}>
                                        <Typography variant="body1" color="text.secondary">No events yet</Typography>
                                    </Box>
                                ) :
                                    <Box sx={{ p: 1 }}>
                                        {events.map((item, index) => (
                                            <ListEventItem item={item} key={index} flyTo={props.flyTo} />
                                        ))}
                                    </Box>
                                }
                            </>

                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: 5 }}>
                                <CircularProgress />
                            </Box>
                        )}

                    </Box>
                    {/*<Accordion defaultExpanded slots={{ transition: Fade }} sx={{boxShadow: 'none', flex: 1}} >
                        
                        <AccordionSummary
                            expandIcon={null}
                            sx={ AccordionSummaryStyle }
                        >
                            <Event sx={{mr: 1}} /><Typography>Events ({events ? events.length : 0})</Typography>
                        </AccordionSummary>

                        <AccordionDetails sx={{height : 'calc(50vh - 106px)',  border: 'none', overflowY: 'auto', bgcolor: '#f4f4f4'}}>
                            
                            { events ? (
                                <>
                                    { events.length === 0 ?  (
                                        <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', height: '100%', p : 5}}>
                                            <Typography variant="body1" color="text.secondary">No events yet</Typography>
                                        </Box>  
                                    ) : 
                                        <>
                                            {events.map((item, index) => (
                                                // <ListFavoritesContainer item={item} key={index} deleteItem={ () => setConfirmMethod({ item, method: removeFavorite} ) } />    
                                                <ListEventItem item={item} key={index} />
                                            ))}
                                        </>
                                    }
                                </>

                            ) : (
                                <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', height: '100%', p : 5}}>
                                    <CircularProgress />
                                </Box>
                            )}

                        </AccordionDetails>
                    </Accordion>*/}


                </Box>

            </Box>
        </Drawer>
    )
}